import React from "react";
import PhoneInput from "react-phone-number-input";
import es from "react-phone-number-input/locale/es";
import "react-phone-number-input/style.css";
import useContactForm from "../../hooks/useContactForm";

const Contact = () => {
  const { formData, handleChange, handlePhoneChange, handleSubmit, showModal, handleClose } = useContactForm();

  const texts = {
    BannerTitle: "¿Hablámos?",
    sectionTitle: "Contacta con nosotros",
    sectionDescription:
      "Utiliza este formulario para enviarnos tus consultas. Te responderemos tan pronto como sea posible.",
    formFields: {
      name: "Nombre",
      company: "Empresa",
      email: "Correo Electrónico",
      phone: "Teléfono",
      message: "Mensaje",
    },
    contactInfo: {
      email: "contacto@ejemplo.com",
      phone: "+34 123 456 789",
    },
  };

  return (
    <div className="w-full">
      {/* Banner */}
      <div
        className="relative bg-cover bg-center h-60 md:h-60"
        style={{ backgroundImage: `url(${require("../../assets/imgs/bg-contact-page.png")})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-primary to-primary/10 flex items-center justify-center">
          <h1 className="text-4xl md:text-5xl font-bold text-white">{texts.BannerTitle}</h1>
        </div>
      </div>

      {/* Modal or feedback message */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md relative">
            {/* Header */}
            <div className="flex justify-between items-center border-b pb-3 mb-4">
              <h2 className="text-xl font-bold text-primary">¡Muchas gracias por tu mensaje!</h2>
              <button
                onClick={handleClose}
                className="text-gray-500 hover:text-gray-700 text-2xl font-bold focus:outline-none"
              >
                &times;
              </button>
            </div>

            {/* Body */}
            <div className="text-gray-700 text-base">
              <p className="mb-4">
                Nos pondremos en contacto contigo lo antes posible. Si lo deseas, también puedes contactarnos por:
              </p>
              <p className="mb-2">
                <span className="font-medium">Email: </span>
                <a href="mailto:contacto@ejemplo.com" className="text-primary hover:underline">
                  contacto@ejemplo.com
                </a>
              </p>
              <p>
                <span className="font-medium">Teléfono: </span>
                <a href="tel:+34123456789" className="text-primary hover:underline">
                  +34 123 456 789
                </a>
              </p>
            </div>

            {/* Footer */}
            <div className="mt-6 text-right">
              <button
                onClick={handleClose}
                className="px-4 py-2 bg-primary text-white rounded-full transition focus:outline-none"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Content */}
      <section className="relative py-14 px-4 md:px-8 bg-background text-neutralDark">
        <div className="max-w-7xl mx-auto">
          <div className="mb-10">
            <h2 className="text-3xl md:text-4xl font-bold text-primary mb-2">{texts.sectionTitle}</h2>
            <p className="text-lg md:text-xl text-neutralDark">{texts.sectionDescription}</p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Form */}
            <div className="col-span-2">
              <form onSubmit={handleSubmit} className="space-y-6">
                {/* Name field */}
                <div className="flex flex-col">
                  <label htmlFor="name" className="mb-2 font-medium text-primary">
                    {texts.formFields.name} <span className="text-primary">*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="border-b border-neutralDark/30 p-2 focus:outline-none"
                    placeholder="Ingresa tu nombre"
                    required
                  />
                </div>

                {/* Company field */}
                <div className="flex flex-col">
                  <label htmlFor="company" className="mb-2 font-medium text-primary">
                    {texts.formFields.company} <span className="text-primary">*</span>
                  </label>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    className="border-b border-neutralDark/30 p-2 focus:outline-none"
                    placeholder="Ingresa tu empresa"
                    required
                  />
                </div>

                {/* Email field */}
                <div className="flex flex-col">
                  <label htmlFor="email" className="mb-2 font-medium text-primary">
                    {texts.formFields.email} <span className="text-primary">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="border-b border-neutralDark/30 p-2 focus:outline-none"
                    placeholder="Ingresa tu correo"
                    required
                  />
                </div>

                {/* Phone field */}
                <div className="flex flex-col">
                  <label htmlFor="phone" className="mb-2 font-medium text-primary">
                    {texts.formFields.phone} <span className="text-primary">*</span>
                  </label>
                  <PhoneInput
                    defaultCountry="ES"
                    international
                    labels={es}
                    name="phone"
                    value={formData.phone}
                    onChange={handlePhoneChange}
                    required
                    className="border-b border-neutralDark/30 p-2 focus:outline-none"
                  />
                </div>

                {/* Message field */}
                <div className="flex flex-col">
                  <label htmlFor="message" className="mb-2 font-medium text-primary">
                    {texts.formFields.message} <span className="text-primary">*</span>
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    value={formData.message}
                    onChange={handleChange}
                    className="border-b border-neutralDark/30 p-2 focus:outline-none"
                    placeholder="Escribe tu mensaje aquí"
                    required
                  />
                </div>

                {/* Submit button */}
                <div>
                  <button
                    type="submit"
                    className="px-8 py-2 bg-primary border border-white hover:bg-primary/90 text-white rounded-full text-lg font-medium transition-all duration-300 group"
                  >
                    Enviar Mensaje
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
