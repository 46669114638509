import React from "react";
import { GraduationCap, BookOpen, Search, Target, ArrowRight } from "lucide-react";

const TrainingBanner = () => {
  const sectionHeading = {
    title: "Formación en IA para Directivos y Equipos",
    subtitle: "Adelántate a la Transformación",
    badge: "Nuevo Programa de IA",
  };

  const features = [
    {
      icon: <BookOpen className="w-6 h-6 text-secondary" />,
      title: "Entiende Qué es la Inteligencia Artificial",
      description: "Comprende los fundamentos de la IA y cómo está transformando industrias en todo el mundo.",
    },
    {
      icon: <Search className="w-6 h-6 text-secondary" />,
      title: "Identifica Casos de Uso en Tu Empresa",
      description: "Aprende a detectar las oportunidades donde la IA puede aportar valor a tus procesos y resultados.",
    },
    {
      icon: <Target className="w-6 h-6 text-secondary" />,
      title: "Implementa la IA con Éxito",
      description: "Descubre metodologías prácticas para integrar soluciones de IA de manera efectiva y sostenible.",
    },
  ];

  const cta = "Descubre el Programa";

  return (
    <section
      id="ai-training"
      className="relative relative py-14 px-4 md:px-8 lg:px-16 bg-cover bg-center bg-no-repeat text-neutralLight"
      style={{
        backgroundImage: `url(${require("../../assets/imgs/training-bg.png")})`,
      }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-gradient-to-r from-primary/50 to-primary/30"></div>

      <div className="relative max-w-7xl mx-auto">
        <div className="grid lg:grid-cols-3 items-center gap-12">
          {/* Left content */}
          <div className="space-y-8 lg:col-span-2">
            <div className="inline-flex items-center px-4 py-2 bg-primary border border-secondary text-white rounded-full">
              <GraduationCap className="w-6 h-6 mr-2 text-secondary" />
              <span className="text-sm font-medium">{sectionHeading.badge}</span>
            </div>

            <div className="space-y-2">
              <h2 className="text-3xl md:text-4xl font-bold text-white leading-tight">{sectionHeading.title}</h2>
              <p className="text-xl md:text-2xl text-white/90">{sectionHeading.subtitle}</p>
            </div>

            <div className="space-y-6">
              {features.map((feature, index) => (
                <div key={index} className="flex flex-col sm:flex-row items-start gap-2 sm:gap-4 text-left">
                  <div className="flex-shrink-0 p-3 bg-secondaryLight/20 rounded-xl">{feature.icon}</div>
                  <div>
                    <h3 className="text-lg font-semibold text-white mb-1">{feature.title}</h3>
                    <p className="text-white/90">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <a
                href="/cursos-ia"
                className="inline-flex items-center px-8 py-4 font-medium text-neutralLight bg-primary rounded-full shadow-md hover:bg-primary/90 transition-all duration-300 group"
              >
                <span>{cta}</span>
                <ArrowRight className="ml-2 w-5 h-5 transform transition-transform group-hover:translate-x-1" />
              </a>
            </div>
          </div>

          {/* Right content - Empty space reserved for visual balance */}
          <div className="relative w-full lg:col-span-1"></div>
        </div>
      </div>
    </section>
  );
};

export default TrainingBanner;
