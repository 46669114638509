import { useState } from "react";
import { functions } from "../config/firebase";
import { httpsCallable } from "firebase/functions";

const useContactForm = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleClose = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      phone: value,
    }));
  };

  const saveContactForm = httpsCallable(functions, "saveContactForm");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      handleShowModal();
      const response = await saveContactForm(formData);

      if (response.data.success) {
        console.log("Form data saved successfully");
        setFormData({ name: "", company: "", email: "", phone: "", message: "" });
      } else {
        console.log("Error saving form data: An error occurred while processing the request.");
      }
    } catch (error) {
      console.error("Error saving form data:", error.message);
    }
  };

  return {
    showModal,
    formData,
    handleClose,
    handleShowModal,
    handleChange,
    handlePhoneChange,
    handleSubmit,
  };
};

export default useContactForm;
