import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import Layout from "./components/Layout/Layout";
import LandingPage from "./pages/LandingPage";
import ContactPage from "./pages/ContactPage";
import AITrainingPage from "./components/Training/AITraining";


const RouteMeta = () => {
  const location = useLocation();

  const metaConfig = {
    "/": {
      title: "OAKMAN - La transformación digital que necesita tu empresa",
    },
    "/contacto": {
      title: "Contacto - OAKMAN",
    },
    "/cursos-ia": {
      title: "Cursos de IA - OAKMAN",
    },
  };

  const currentMeta = metaConfig[location.pathname] || {
    title: "OAKMAN - La transformación digital que necesita tu empresa",
  };

  return (
    <Helmet>
      <title>{currentMeta.title}</title>
    </Helmet>
  );
};

const App = () => (
  <HelmetProvider>
    <BrowserRouter>
      <RouteMeta />
      <Routes>
        <Route path="/" element={<Layout><LandingPage /></Layout>} />
        <Route path="/contacto" element={<Layout><ContactPage /></Layout>} />
        <Route path="/cursos-ia" element={<Layout><AITrainingPage /></Layout>} />
      </Routes>
    </BrowserRouter>
  </HelmetProvider>
);

export default App;
