import { useState } from "react";
import { Menu, X } from "lucide-react";
import Logo from "../../assets/logos/oakman-logo.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navLinks = [
    { name: "Nuestros Servicios", href: "/#our-services" },
    { name: "Formación", href: "/cursos-ia" },
    { name: "Sobre Nosotros", href: "/#about-us" },
    { name: "Contacto", href: "/contacto" },
  ];

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <div className="relative">
      <nav className="bg-transparent">
        <div className="mx-auto px-2 lg:px-10 py-4">
          <div className="flex items-center justify-between">
            {/* Logo */}
            <a href="/">
              <img src={Logo} alt="Oakman Logo" className="h-12 w-auto" />
            </a>

            {/* Mobile menu button */}
            <div className="lg:hidden">
              <button
                onClick={toggleMenu}
                className="inline-flex items-center justify-center p-2 rounded-md text-primary hover:text-accent hover:bg-neutral-200 transition-all"
              >
                {isOpen ? <X className="block h-8 w-8" /> : <Menu className="block h-8 w-8" />}
              </button>
            </div>

            {/* Desktop navigation */}
            <div className="hidden lg:flex lg:items-center justify-between w-full">
              <ul className="flex items-center gap-2 mx-auto">
                {navLinks.map((link) => (
                  <li key={link.name}>
                    <a
                      href={link.href}
                      className="text-neutralDark px-4 py-2 rounded-3xl relative hover:text-primary hover:font-bold after:content-[''] after:absolute after:w-full after:h-[2px] after:bg-secondary after:left-0 after:bottom-0 after:scale-x-0 after:origin-right hover:after:scale-x-100 hover:after:origin-left transition-all duration-300"
                    >
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
              {/* TODO: Call to action button */}
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile menu, show/hide based on menu state */}
      <div
        className={`${
          isOpen ? "block" : "hidden"
        } lg:hidden absolute top-16 left-0 right-0 bg-white shadow-lg z-50`}
      >
        <div className="px-4 pt-2 pb-3 space-y-2">
          {navLinks.map((link) => (
            <a
              key={link.name}
              href={link.href}
              className="block px-4 py-2 text-neutral-800 hover:text-primary hover:bg-neutral-100 rounded-md transition-all"
            >
              {link.name}
            </a>
          ))}
          {/* TODO: Mobile CTA button */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
