import React from "react";
import PhoneInput from "react-phone-number-input";
import es from "react-phone-number-input/locale/es";
import "react-phone-number-input/style.css";
import useContactForm from "../../hooks/useContactForm";

const labels = {
  name: "Nombre",
  company: "Empresa",
  email: "Correo Electrónico",
  phone: "Teléfono",
  message: "Mensaje",
};

const ContactForm = () => {
  const { formData, handleChange, handlePhoneChange, handleSubmit, showModal, handleClose } = useContactForm();

  return (
    <>
      {/* Modal or feedback message */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md relative">
            {/* Header */}
            <div className="flex justify-between items-center border-b pb-3 mb-4">
              <h2 className="text-xl font-bold text-primary">¡Muchas gracias por tu mensaje!</h2>
              <button
                onClick={handleClose}
                className="text-gray-500 hover:text-gray-700 text-2xl font-bold focus:outline-none"
              >
                &times;
              </button>
            </div>

            {/* Body */}
            <div className="text-gray-700 text-base">
              <p className="mb-4">
                Nos pondremos en contacto contigo lo antes posible. Si lo deseas, también puedes contactarnos por:
              </p>
              <p className="mb-2">
                <span className="font-medium">Email: </span>
                <a href="mailto:contacto@oakmanvs.com" className="text-primary hover:underline">
                  contacto@oakmanvs.com
                </a>
              </p>
              <p>
                <span className="font-medium">Teléfono: </span>
                <a href="tel:+34696747490" className="text-primary hover:underline">
                  +34 696 747 490
                </a>
              </p>
            </div>

            {/* Footer */}
            <div className="mt-6 text-right">
              <button
                onClick={handleClose}
                className="px-4 py-2 bg-primary text-white rounded-full transition focus:outline-none"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-6">
        {/* Name field */}
        <div className="col-span-1 flex flex-col">
          <label htmlFor="name" className="mb-2 font-medium text-primary">
            {labels.name} <span className="text-accent">*</span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="border-b border-neutralDark/30 p-2 focus:outline-none"
            placeholder="Ingresa tu nombre"
            required
          />
        </div>

        {/* Company field */}
        <div className="col-span-1 flex flex-col">
          <label htmlFor="company" className="mb-2 font-medium text-primary">
            {labels.company} <span className="text-accent">*</span>
          </label>
          <input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            onChange={handleChange}
            className="border-b border-neutralDark/30 p-2 focus:outline-none"
            placeholder="Ingresa tu empresa"
            required
          />
        </div>

        {/* Email field */}
        <div className="col-span-1 flex flex-col">
          <label htmlFor="email" className="mb-2 font-medium text-primary">
            {labels.email} <span className="text-accent">*</span>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="border-b border-neutralDark/30 p-2 focus:outline-none"
            placeholder="Ingresa tu correo"
            required
          />
        </div>

        {/* Phone field */}
        <div className="col-span-1 flex flex-col">
          <label htmlFor="phone" className="mb-2 font-medium text-primary">
            {labels.phone} <span className="text-accent">*</span>
          </label>
          <PhoneInput
            defaultCountry="ES"
            international
            labels={es}
            name="phone"
            value={formData.phone}
            onChange={handlePhoneChange}
            required
            className="border-b border-neutralDark/30 p-2 focus:outline-none"
          />
        </div>

        {/* Message field */}
        <div className="col-span-1 md:col-span-2 lg:col-span-1 xl:col-span-2 flex flex-col">
          <label htmlFor="message" className="mb-2 font-medium text-primary">
            {labels.message} <span className="text-accent">*</span>
          </label>
          <textarea
            id="message"
            name="message"
            rows="4"
            value={formData.message}
            onChange={handleChange}
            className="border-b border-neutralDark/30 p-2 focus:outline-none"
            placeholder="Escribe tu mensaje aquí"
            required
          />
        </div>

        {/* Submit button */}
        <div className="col-span-1 md:col-span-2 lg:col-span-1 xl:col-span-2">
          <button
            type="submit"
            className="flex items-center justify-center gap-2 w-full bg-primary text-neutralLight rounded-full p-3 font-medium transition"
          >
            Enviar Mensaje
          </button>
        </div>
      </form>
    </>
  );
};

export default ContactForm;
