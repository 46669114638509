import React from "react";
import { ChevronRight, Target, Cog, Lightbulb, BookOpen, Shield, RefreshCcw } from "lucide-react";

const AITrainingPage = () => {
  // Datos para las tarjetas de identificación de oportunidades
  const opportunityCards1 = [
    {
      icon: <Target className="w-12 h-12 text-primary" />,
      title: "Análisis de procesos internos",
      content:
        "Examinar en detalle las operaciones diarias para detectar cuellos de botella y áreas donde la IA puede mejorar la eficiencia, reducir costos y potenciar la productividad.",
    },
    {
      icon: <Cog className="w-12 h-12 text-primary" />,
      title: "Diseño de casos de uso",
      content:
        "Definir aplicaciones concretas de IA alineadas con los objetivos estratégicos de la empresa, asegurando que cada implementación genere valor real y medible.",
    },
    {
      icon: <Lightbulb className="w-12 h-12 text-primary" />,
      title: "Estrategia de implementación",
      content:
        "Crear un plan estructurado para la adopción de IA, asegurando una integración progresiva, escalable y compatible con la infraestructura tecnológica actual.",
    },
  ];

  const opportunityCards2 = [
    {
      icon: <BookOpen className="w-12 h-12 text-primary" />,
      title: "Metodologías prácticas",
      content:
        "Aplicar enfoques ágiles y metodologías probadas para gestionar proyectos de IA, desde la ideación hasta la ejecución, maximizando el impacto y reduciendo riesgos.",
    },
    {
      icon: <Shield className="w-12 h-12 text-primary" />,
      title: "Aspectos éticos y legales",
      content:
        "Considerar regulaciones, privacidad de datos y principios éticos para garantizar el uso responsable y transparente de la inteligencia artificial en la empresa.",
    },
    {
      icon: <RefreshCcw className="w-12 h-12 text-primary" />,
      title: "Gestión del cambio",
      content:
        "Asegurar que los equipos adopten la IA de manera positiva, proporcionando formación, comunicación clara y estrategias para minimizar la resistencia al cambio.",
    },
  ];

  // Datos para los beneficios
  const benefitCards = [
    {
      title: "Decisiones estratégicas fundamentadas",
      content:
        "Accede a información procesada por IA para tomar decisiones más rápidas, precisas y basadas en datos reales. Mejora la planificación, identifica oportunidades y minimiza riesgos con insights predictivos.",
    },
    {
      title: "Estrategias de IA alineadas con tus objetivos",
      content:
        "Implementa inteligencia artificial de manera efectiva, asegurando que cada solución tecnológica se integre con la visión y las metas de tu empresa. Optimiza procesos, automatiza tareas y mejora la eficiencia operativa.",
    },
    {
      title: "Posicionamiento como líder en innovación",
      content:
        "Diferénciate en tu sector adoptando tecnologías de IA que impulsen la transformación digital. Mejora la experiencia del cliente, personaliza servicios y destaca como una empresa innovadora y preparada para el futuro.",
    },
  ];

  // Función para renderizar cada tarjeta de íconoconst renderIconCard = ({ icon, title, content }, index) => (const renderIconCard = ({ icon, title, content }, index) => (
  const renderIconCard = ({ icon, title, content }, index) => (
    <div
      key={index}
      className="bg-white rounded-xl shadow-lg p-6 transform transition-all flex flex-col sm:flex-row items-start sm:items-center gap-4 sm:gap-6"
    >
      {/* Ícono alineado arriba en móviles y a la izquierda en pantallas grandes */}
      <div className="text-primary text-4xl sm:text-3xl">{icon}</div>

      {/* Contenido alineado a la izquierda */}
      <div className="text-left">
        <h3 className="text-xl font-semibold text-primary mb-2">{title}</h3>
        <p className="text-gray-700">{content}</p>
      </div>
    </div>
  );

  return (
    <div className="w-full">
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center h-60 md:h-60"
        style={{ backgroundImage: `url(${require("../../assets/imgs/hero-training.png")})` }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-primary to-primary/10 flex items-center justify-center">
          <h1 className="text-4xl md:text-5xl font-bold text-white text-center">Cursos de Inteligencia Artificial</h1>
        </div>
      </div>

      {/* Introducción */}
      <section className="bg-gray-100 py-12">
        <div className="max-w-7xl mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center">
            <p className="text-lg text-gray-700">
              La Inteligencia Artificial no es solo una tecnología del futuro, sino una realidad que ya está
              transformando la forma en que operan las empresas. Conocer sus fundamentos y aplicaciones permite a
              directivos y profesionales tomar mejores decisiones estratégicas e innovar en su sector. Nuestro programa
              te ayudará a entender la IA de manera práctica y accesible.
            </p>
          </div>
        </div>
      </section>

      {/* ¿Por qué es importante entender la IA? */}
      <section className="container mx-auto py-14 px-4 text-neutralDark">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold text-primary mx-auto text-center">
            ¿Por qué es importante entender la IA?
          </h2>
          <p className="text-xl text-neutralDark mt-4 max-w-2xl mx-auto text-center">
            Si solo tienes un martillo, todos los problemas te parecerán un clavo
          </p>
          <div className="my-8 w-16 h-[3px] bg-secondary rounded mx-auto" />
          <p className="text-lg text-left mb-8">
            La Inteligencia Artificial (IA) está revolucionando el mundo empresarial, abriendo nuevas oportunidades y
            redefiniendo la forma en que las organizaciones operan. Sin embargo, para aprovechar plenamente su
            potencial, es esencial comprender qué es, cómo funciona y cómo se puede integrar eficazmente en los procesos
            de negocio.
          </p>
          <p className="text-lg text-left mb-8">Nuestro programa aborda aspectos esenciales de la IA, como:</p>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {/* Primera tarjeta */}
            <div className="flex flex-col bg-slate-100 rounded-xl shadow-lg overflow-hidden h-auto">
              <img
                src={require("../../assets/imgs/1.png")}
                alt="Fundamentos de la IA"
                className="w-full h-[160px] object-cover bg-gray-200"
              />
              <div className="p-6 flex-grow">
                <h3 className="text-2xl font-semibold text-primary mb-3 text-left">
                  Fundamentos de la Inteligencia Artificial
                </h3>
                <p className="text-gray-700 text-left">
                  Comprender los principios básicos de la IA es esencial para cualquier empresa que quiera aprovechar su
                  potencial. Desde el aprendizaje automático hasta las redes neuronales, conocer su funcionamiento nos
                  permite tomar decisiones estratégicas más informadas.
                </p>
              </div>
            </div>

            {/* Segunda tarjeta */}
            <div className="flex flex-col bg-slate-100 rounded-xl shadow-lg overflow-hidden h-auto">
              <img
                src={require("../../assets/imgs/2.png")}
                alt="Casos de uso reales"
                className="w-full h-[160px] object-cover bg-gray-200"
              />
              <div className="p-6 flex-grow">
                <h3 className="text-2xl font-semibold text-primary mb-3 text-left">
                  Casos de uso reales de IA en empresas
                </h3>
                <p className="text-gray-700 text-left">
                  La IA está transformando sectores enteros, optimizando la eficiencia operativa y permitiendo la
                  personalización masiva de productos y servicios. Analizaremos ejemplos concretos de cómo la IA puede
                  impulsar la competitividad empresarial.
                </p>
              </div>
            </div>

            {/* Tercera tarjeta */}
            <div className="flex flex-col bg-slate-100 rounded-xl shadow-lg overflow-hidden h-auto">
              <img
                src={require("../../assets/imgs/3.png")}
                alt="Metodología en el desarrollo de proyectos IA"
                className="w-full h-[160px] object-cover bg-primary"
              />
              <div className="p-6 flex-grow">
                <h3 className="text-2xl font-semibold text-primary mb-3 text-left">
                  Metodología en el desarrollo de proyectos IA
                </h3>
                <p className="text-gray-700 text-left">
                  Para desarrollar soluciones de IA exitosas, es crucial seguir un enfoque estructurado. Desde la
                  definición del problema y la recopilación de datos hasta la implementación y monitoreo, cada etapa
                  requiere planificación y validación para garantizar resultados óptimos.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Identificación de oportunidades */}
      <section className="container mx-auto py-12 px-4">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold text-primary mx-auto text-center">
            Identifica las oportunidades en tu empresa
          </h2>
          <div className="my-8 w-16 h-[3px] bg-secondary rounded mx-auto" />
          <p className="text-lg text-gray-600 text-left mb-8">
            Una de las claves del éxito en la implementación de la IA es saber cómo y dónde aplicarla. No se trata solo
            de adoptar tecnología, sino de hacerlo de manera estratégica para mejorar la eficiencia, la toma de
            decisiones y la competitividad. Analizar los procesos internos, identificar áreas de oportunidad y diseñar
            una estrategia de implementación adecuada pueden marcar la diferencia entre un cambio positivo y una
            inversión poco efectiva.
          </p>

          <div className="space-y-8">
            <div className="grid md:grid-cols-3 gap-8">
              {opportunityCards1.map((card, index) => renderIconCard(card, index))}
            </div>
            <div className="grid md:grid-cols-3 gap-8">
              {opportunityCards2.map((card, index) => renderIconCard(card, index))}
            </div>
          </div>
        </div>
      </section>

      {/* Beneficios */}
      <section className="bg-slate-100 text-white py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-primary text-center">Beneficios para tu organización</h2>
          <div className="my-8 w-16 h-[3px] bg-secondary rounded mx-auto" />
          <div className="max-w-3xl mx-auto space-y-6">
            {benefitCards.map((item, index) => (
              <div
                key={index}
                className="flex bg-white text-primary rounded-lg p-6 shadow-lg transform transition-all hover:scale-105"
              >
                {/* Chevron alineado arriba y de color secundario */}
                <ChevronRight className="w-8 h-8 text-secondary flex-shrink-0 self-start mt-1" />

                {/* Contenido alineado a la izquierda */}
                <div className="ml-4">
                  <p className="text-lg font-bold">{item.title}</p>
                  <p className="text-base">{item.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16 text-center text-white bg-cover bg-center bg-primary">
        <div className="container mx-auto px-4">
          <div className="inline-block">
            <p className="text-3xl font-bold drop-shadow-lg">La IA no es el futuro. Es el presente.</p>
            <p className="text-lg max-w-2xl mx-auto drop-shadow-md mt-6">
              Este es el momento de prepararte y preparar a tu equipo para afrontar los desafíos y aprovechar las
              oportunidades que ofrece la Inteligencia Artificial.
            </p>
            <button className="mt-8 inline-block px-8 py-2 bg-primary border border-white hover:bg-primary/90 text-white rounded-full text-lg font-medium transition-all duration-300 group">
              <a href="/contacto">Inscríbete ahora</a>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AITrainingPage;
