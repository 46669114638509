import { Linkedin, Mail, Phone } from "lucide-react";
import Logo from "../../assets/logos/oakman-logo.png";

const Footer = () => {
  // Data for navigation links
  const navLinks = [
    { label: "Nuestros Servicios", href: "/#our-services" },
    { label: "Formación", href: "/cursos-ia" },
    { label: "Sobre Nosotros", href: "/#about-us" },
    { label: "Contacto", href: "/contacto" },
  ];

  // Data for legal links
  const legalLinks = [
    { href: "#privacy-policy", label: "Política de Privacidad" },
    { href: "#legal-notice", label: "Aviso Legal" },
    { href: "#cookies-policy", label: "Política de Cookies" },
  ];

  return (
    <footer>
      {/* Top Section */}
      <div className="bg-transparent text-primary py-12 px-6 bg-slate-100">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Column 1: Logo and Contact Info */}
          <div>
            <a href="/">
              <img src={Logo} alt="Oakman Logo" className="h-12" />
            </a>
            <div className="mt-4 flex items-center gap-2">
              <Mail className="w-5 h-5 text-primary" />
              <a href="mailto:contacto@oakmanvs.com">
                contacto@oakmanvs.com
              </a>
            </div>
            <div className="flex items-center gap-2 mt-2">
              <Phone className="w-5 h-5 text-primary" />
              <a href="tel:+34696747490">
                +34 696 747 490
              </a>
            </div>
            <div className="mt-4 flex items-center gap-2">
              <Linkedin className="w-5 h-5 text-primary" />
              <a
                href="https://www.linkedin.com/company/oakman"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
            </div>
          </div>

          {/* Column 2: Useful Links */}
          <div>
            <h3 className="text-xl font-semibold text-primary">Enlaces Útiles</h3>
            <ul className="mt-4 space-y-2">
              {navLinks.map((link, index) => (
                <li key={index}>
                  <a href={link.href}>
                    {link.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Column 3: Legal Information */}
          <div>
            <h3 className="text-xl font-semibold text-primary">Información Legal</h3>
            <ul className="mt-4 space-y-2">
              {legalLinks.map((link, index) => (
                <li key={index}>
                  <a href={link.href}>
                    {link.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="bg-primary text-white py-6">
        <div className="max-w-7xl mx-auto text-center">
          <p className="text-sm">
            © {new Date().getFullYear()} OAKMAN. Todos los derechos reservados.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
